.text-input {
  margin-bottom: 5px;
}

.text-input--remove-btn {
  position: absolute;
  color: #555;
  z-index: 3;
  top: 11px;
  right: 5px;
  cursor: pointer;
}

.text-input--label {
  font-weight: 400;
  margin-bottom: 0px;
}

.text-input--addon-symbol {
  padding-left: 8px;
  padding-right: 8px;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Label */
.align-input {
  height: 34px;
  padding-top: 6px;
  padding-bottom: 6px;
}