.meter {
  box-sizing: content-box;
  height: 10px;
  position: fixed;
  width: 100%;
  z-index: 99;
}
.meter > span {
  display: block;
  height: 100%;
  background-color: rgb(13,110,253);
  background-image: linear-gradient(
    center bottom,
    rgb(13,110,253) 37%,
    rgb(13,110,253) 69%
  );
  position: relative;
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.nostripes > span > span,
.nostripes > span::after {
  background-image: none;
}