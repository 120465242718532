/*Spinner*/
.spinner {
  height: 150px;
}
.spinner.mask {
  height: auto;
}
.spinner-content {
  width: 100%;
  text-align: center;
  font-size: 20px;
  position: relative;
  top: 50%;
  cursor: default;
}
/* Mask */
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.5;
  z-index: 5;
}
.mask.in-row {
 /* left: 15px;
  right: 15px;
  */
}
/* Progress */
.progress-mask-top {
  position: absolute;
  top: 45%;
  width: 100%;
  z-index: 6;
}
.progress-mask-top .well {
  background-color: #ddd;
}
.progress-bar {
  background-color: #0070ba;
}
/* Save hint icon */
.save-hint-icon {
  font-size: 120%;
  margin-right: 5px;
}
/*Not found*/
.not-found, .plain-message {
  height: 150px;
  text-align: center;
}
.not-found > span, .plain-message > span {
  font-size: 20px;
  position: relative;
  top: 50%;
  width: 100%;
}
/*Empty msg*/
.empty-message {
  margin: 50px 0;
  text-align: center;
}
/* Email Campaign */
.email-templates .dropdown {
  width: 100%;
}
.email-templates .template-box {
  cursor: pointer;
}
.email-templates .template-thumb {
  height: 220px;
  background-size: cover;
  border: 2px solid #ccc;
  border-radius: 4px;
}
.email-templates .template-title {
  text-align: center;
}
.email-templates .template-title:hover {
  text-decoration: underline;
}
.email-templates .template-box.selected .template-thumb {
  border: 2px solid #0070ba;
}
.email-templates .template-box.selected .template-title {
  text-transform: uppercase;
  color: #0070ba;
}
.email-templates .primary-label {
  color: #0070ba;
}
/* Misc menus & toolbars */
#top-menu-box {
  display: inline-flex;
  float: right;
  z-index: 10;
  position: relative;
}
#tab-side-toolbar {
  float: right;
  z-index: 2;
  position: relative;
}
#tab-side-toolbar .dropdown, #tab-side-toolbar .dropdown-menu {
  width: 100%;
}
#top-menu-box-left {
  display: inline-flex;
}
.tab-content {
 /* padding: 16px 8px 16px 8px!important;
  */
}
/* Account selector */
#input-group-account-selector-box .fa-user, #account-selector-box .fa-user {
  margin-right: 10px;
  color: #0070ba;
}
#input-group-account-selector-box .dropdown, #account-selector-box .dropdown, #input-group-account-selector-box .dropdown > button, #account-selector-box .dropdown > button, #input-group-account-selector-box .dropdown > .dropdown-menu, #account-selector-box .dropdown > .dropdown-menu {
  min-width: 100%;
}
.platform-text {
  font-family: monospace;
}
#account-selector-box {
 /* margin-top: 20px;
  */
 /* display: inline-block;
  */
 /* float: right;
  */
}
#account-selector-box .fa-user {
  float: left;
  position: relative;
  top: 3px;
}
#account-selector-box .dropdown .dropdown-toggle {
  white-space: nowrap;
  overflow: hidden;
  max-width: 240px;
  text-overflow: ellipsis;
  padding-right: 30px;
}
#account-selector-box .caret {
  position: absolute;
  right: 12px;
  top: 14px;
}
#account-selector-box.compact {
  margin-top: 0;
  padding-left: 10px;
}
.page-title h1, .page-title h2, .page-title h3, .page-title h4, .page-title h5 {
  display: inline-block;
}
.page-title .fa {
  margin: 0px 12px;
}
.basic-dropdown .dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}
.dropdown-toggle.menu-auto-width ~ .dropdown-menu {
  width: auto !important;
}
.dropdown-toggle:hover::after,
.dropdown-toggle:focus::after,
.dropdown-toggle:active::after,
.dropdown-toggle:hover b,
.dropdown-toggle:focus b,
.dropdown-toggle:active b {
  color: #fff;
}
/* Text */
.primary-text {
  color: #0070ba;
}
.disabled-text {
  color: #555;
}
/* Centered model */
.v-center-dialog .modal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
.v-center-dialog .modal-dialog .modal-content {
  margin: auto;
}
@media only screen and (max-width: 767px) {
  #top-menu-box {
    display: block;
    width: 100%;
    position: static;
    float: none;
   /* margin-top: 10px;
    */
 }
  #tab-side-toolbar {
    width: 100%;
    margin-bottom: 15px;
 }
  #top-menu-box-left {
    margin-bottom: 5px;
    float: right;
    position: absolute;
   /* top: 0;
    */
    right: 0;
 }
  #backfill-banner-minimized + #dashboard-body #top-menu-box-left {
    right: 77px;
 }
  #account-selector-box {
    margin-top: 0;
    display: block;
    float: none;
 }
  #account-selector-box, #account-selector-box > .dropdown, #account-selector-box > .dropdown > button, #account-selector-box > .dropdown > .dropdown-menu {
    width: 100%;
 }
}
/* Responsive */
@media only screen and (max-width: 479px) {
  .col-xxs-12 {
    width: 100%;
    display: block !important;
    padding-left: 15px;
    padding-right: 15px;
 }
  .col-xxs-6 {
    width: 50%;
    display: block !important;
    padding-left: 15px;
    padding-right: 15px;
 }
  .hidden-xxs {
    display: none !important;
 }
  .block-xxs {
    display: block !important;
 }
  .inline-xxs {
    display: inline !important;
 }
  .basic-dropdown {
    width: 100%;
 }
  .basic-dropdown .dropdown, .basic-dropdown .dropdown-menu {
    width: 100% !important;
 }
}
@media only screen and (max-width: 544px) {
  #subscribers-body .col-xxs-12 {
    width: 100%;
    display: block !important;
    padding-left: 15px;
    padding-right: 15px;
 }
  #subscribers-body .col-xxs-6 {
    width: 50%;
    display: block !important;
    padding-left: 15px;
    padding-right: 15px;
 }
  #subscribers-body .hidden-xxs {
    display: none !important;
 }
  #subscribers-body .block-xxs {
    display: block !important;
 }
  #subscribers-body .inline-xxs {
    display: inline !important;
 }
}
/* Track Overview Seller Box */
.seller-tracking-section {
  overflow-y: scroll;
}
/* Other */
:focus, a:focus {
  outline: none;
}
.sub-form-group {
  border: 1px solid #ddd;
  margin: 0 0 15px 0 !important;
  padding: 5px 5px 15px 5px;
}
.loading-line {
  height: 10px;
  margin: 10px;
  animation: pulse 1s infinite ease-in-out;
}
@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
 }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
 }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
 }
}
.fa-primary {
  color: #0070ba;
}
.token-box {
  border-radius: 4px;
  background-color: #0070ba;
  color: white;
}
/* Form Select */
.form-select {
  color: #0070ba;
  font-size: 14px;
  font-weight: 700;
}
.purchase-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 544px) {
  .purchase-area {
    flex-direction: column-reverse;
 }
}
.offcanvas {
  transition: none;
}
.insurance-terms {
  font-size: 0.75em;
  padding-top: 20px;
}
.auto-deposit-terms {
  font-size: 0.75em;
  bottom: 0px;
  left: 0;
  padding: 25px;
}
.picker-dialog {
  z-index: 9999999 !important;
}
@media only screen and (max-width: 544px) {
  .insurance-terms {
    position: relative;
    padding-top: 20px;
 }
}
@media only screen and (min-height: 815px) {
  .insurance-terms {
    padding-top: 20px;
    position: absolute;
    bottom: 0;
 }
}
.toast-position {
  top: 100px !important;
  padding-right: 15px;
}
/* .dropdown-button {
  padding: 0;
  color: black;
  background-color: transparent;
  margin-left: 3px;
  font: inherit
}
.dropdown > button:focus:not(:focus-visible), .dropdown > button:hover {
  color: black;
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}
*/
.shipping-input {
  color: #0070ba;
  font-size: 14px;
  font-weight: 700;
}
.report-container {
  padding: 25px;
  background-color: white;
  position: relative;
}
@media only screen and (max-width: 479px) {
  .report-container {
    padding: 20px;
  }
}
@media only screen and (max-width: 399px) {
  .report-container {
    padding: 14px;
  }
}

.progress-bar {
  background-color: #4a8efc !important;
}