.range-selector {
  margin-left: 10px;
  float: right;
  text-align: right;
  z-index: 3;
  position: relative;
}
.range-selector .text-input-box {
  padding: 0px 12px;
  text-align: center;
}
.range-selector input {
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0;
  display: inline-block;
  height: auto;
  border-radius: 0;
}
.range-selector .input-group-btn {
  width: auto;
}
.range-selector .input-group .form-control {
  float: none;
}
.range-selector .checkbox label {
  min-width: 270px;
  text-align: left;
}
.range-selector .date-input {
  width: 90px;
}
.range-selector > .dropdown {
  width: 100%;
}
.range-selector #range-selector-dropdown, .range-selector #range-selector-dropdown + .dropdown-menu {
  width: 100%;
  text-align: center;
}
.range-selector #range-selector-dropdown + .dropdown-menu {
  min-width: 325px;
}
.range-selector #range-selector-dropdown + .dropdown-menu > li > a {
  padding: 3px 27px;
}
.range-selector .dropdown-toggle .fa {
  margin-right: 5px;
}
.range-selector .month-nav {
  width: 265px;
  margin: auto;
}
.range-selector .month-nav .dropdown-box, .range-selector .month-nav .dropdown-box .dropdown, .range-selector .month-nav .dropdown-box .dropdown-toggle {
  width: 100%;
}
#range-selector-dropdown {
  padding: 0 12px;
}
#range-selector-dropdown .caret {
  border-width: 6px;
}
#range-selector-title {
  border-right: 1px solid #ccc;
  height: 32px;
}
.range-selector .DateRangePicker .DateRangePicker__MonthHeader, .DateRangePicker__PaginationArrow {
  display: none;
}
#date-picker-month-nav, #date-picker-year-nav {
  box-shadow: none;
}
.range-selector-section {
  margin-bottom: 10px;
}
.range-selector-title {
  margin-right: 5px;
  display: inline-block;
  width: 125px;
}
.range-selector .DateRangePicker__CalendarSelection {
  background-color: #0070ba;
  border-color: #0070ba;
}
.range-selector .DateRangePicker__CalendarHighlight--single {
  border-color: #0070ba;
}
.range-selector .quick-selection {
  text-align: center;
  max-width: 100%;
  white-space: normal;
}
.range-selector .quick-selection .btn {
  margin: 5px 10px;
  padding: 2px 5px;
  width: 110px;
}
.range-selector b, .range-selector .fa {
  color: #0070ba;
}
#date-picker-year-nav, #date-picker-year-nav + .dropdown-menu {
  min-width: 82px;
  width: 100%;
}
.quick-selection .divider-col-2, .quick-selection .divider-col-3 {
  display: none;
}
#traffic-date-menu-container {
  padding: 10px;
  float: right;
}
.rdrDateDisplayWrapper {
  display: none;
}
@media only screen and (max-width: 479px) {
  .quick-selection .divider-col-2 {
    display: block;
    width: 100%;
    height: 1px;
 }
  .quick-selection .divider-col-3 {
    display: none;
 }
  #traffic-date-menu-container {
    float: none;
    padding: 0;
    display: flex;
 }
  #traffic-date-menu-container .dropdown {
    flex: 1;
 }
  #traffic-date-menu-container .dropdown-toggle {
    width: 100%;
    text-align: left;
 }
}
@media only screen and (max-width: 767px) and (min-width: 480px) {
  .quick-selection .divider-col-3 {
    display: block;
    width: 100%;
    height: 1px;
 }
}
@media only screen and (max-width: 767px) {
  .range-selector {
    margin-bottom: 15px;
    width: 100%;
    float: none;
    margin-left: 0;
 }
  .range-selector #range-selector-dropdown, .range-selector #range-selector-dropdown + .dropdown-menu {
    width: 100%;
 }
  .range-selector .text-input-box {
    padding: 0 6px;
 }
  .range-selector-container, .range-selector-container .dropdown {
    position: static;
    width: 100%;
 }
}
