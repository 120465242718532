/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}
  
.react-dropdown-tree-select .material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.react-dropdown-tree-select .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  padding-right: 6px;
  padding-left: 2px;
}

.react-dropdown-tree-select .toggle.collapsed::after {
  cursor: pointer;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f0d7";
}

.react-dropdown-tree-select .toggle.expanded::after {
  cursor: pointer;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f0d8";
}

/* checkbox styles */
.react-dropdown-tree-select .checkbox-item {
  position: relative;
  width: 14px;
  height: 14px;
  margin-left: -20px;
  margin-right: 6px;
  margin-top: 2px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
}

.react-dropdown-tree-select .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid #444;
  border-radius: 2px;
  /* transition: all 0.3s ease-in-out; */
}

.react-dropdown-tree-select .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #2196f3;
}

.react-dropdown-tree-select .node-label {
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-overflow: ellipsis
}

.react-dropdown-tree-select input.search {
  visibility: hidden;  
}

.react-dropdown-tree-select ul.tag-list {
  display: none;
}

.react-dropdown-tree-select ul.root {
  margin-left: -40px;
}

.react-dropdown-tree-select .infinite-scroll-component {
  margin-top: -20px;
}

.filter-container .react-dropdown-tree-select .checkbox-item {
  display: none;
}

.react-dropdown-tree-select .node {
  white-space: nowrap;
}

.react-dropdown-tree-select .node.selected > label > span {
  font-weight: 700;
}

.react-dropdown-tree-select .node.deselected > label > span {
  font-weight: 700;
  text-decoration: line-through;
}

.filter-container .fa.fa-info-circle {
  opacity: 0.6;
}