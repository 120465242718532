
.user-item-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5em;
}

.user-channel-list {
  padding-inline-start: 5px;
  list-style-type: none;
}

.user-list--link-text {
  cursor: pointer;
  color: #337ab7;
}

.user-list--link-text:hover {
  color: #23527c;
  text-decoration: underline;
}