@media only screen and (max-width: 479px) {
  .inventory-filter-zone {
    flex-direction: column;
    gap: 10px;
  }
  .inventory-search-zone {
    flex-direction: column-reverse;
    align-items: start;
    gap: 10px;
  }
  .inventory-search-area {
    display: flex;
    align-items: center;
  }
}