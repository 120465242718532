.shared-accounts-td {
  width: 10%;
}
.shared-accounts-permission-td {
  width: 35%;
}
.shared-accounts-permission-label {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
#dropdown-custom-shared-accounts::after {
  display: none !important;
}
#settings-tabs .shared-accounts form {
  padding-right: 5px;
}
#settings-tabs .shared-accounts .manage-shared-accounts > div.row {
  border-top: 1px solid lightgrey;
  margin: auto;
  padding: 5px;
  overflow-x: auto;
}
#settings-tabs .shared-accounts span.contact {
  cursor: default;
  margin-right: 5px;
  float: left;
}
#settings-tabs .shared-accounts span.label {
  cursor: default;
  float: left;
  margin: 5px 0 5px 0;
}
#settings-tabs .shared-accounts .manage-shared-accounts > div.row > div {
  padding: 0;
  line-height: 2.2em;
}
#settings-tabs .shared-accounts .manage-shared-accounts > div.row:nth-child(odd) {
  background-color: whitesmoke;
}
#settings-tabs .shared-accounts .manage-shared-accounts > div.row:hover {
  background-color: #f0f0f0;
}
@media (min-width: 768px) {
  #settings-tabs .shared-accounts .manage-shared-accounts > div.row > div button {
    float: right;
    width: 7em;
 }
  #settings-tabs .shared-accounts span.label {
    float: right;
    margin-top: 9px;
 }
}
.CheckboxMenu {
  transform: translate(-45px, 0) !important;
}
