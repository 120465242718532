.autocomplete-container {
  position: relative;
}

.autocomplete-menu {
  top: 33px;
  left: 36px;
  z-index: 100;
  list-style: none;
  position: absolute;
  background: white;
  border-color: rgb(204, 204, 204);
  border-style: solid;
  border-radius: 0 0 4px 4px;
  border-width: 1px;
  padding: 0 0 0 0;
}

.autocomplete-item {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
  transition: 0.1s;
}

.autocomplete-item:first-child {
  margin-top: 2px;
}

.autocomplete-item:last-child {
  border-bottom: hidden;
  margin-bottom: 2px;
}

.autocomplete-item-active {
  background: rgb(230, 230, 230);
}