#sub-analytics-body.third-party {
  margin-top: 0;
  max-width: none !important;
}

#sub-analytics-footer.third-party {
  position: static;
}

.third-party-hide {
  display: none;
}