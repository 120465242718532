@import './backdropbackground.css';
 #plan-body .pricing-paragraph {
	 text-transform: uppercase;
}
 #plan-body .option-card {
	 font-family: 'Maison neue', sans-serif;
	 color: #64738b;
	 position: relative;
	 margin-bottom: 15px;
	 padding: 17px 12px;
	 text-align: center;
	 border-radius: 6px;
	 box-shadow: rgba(0, 0, 0, 0.498) 0px 0px 8px 0px;
	 font-size: 15px;
	 height: 450px;
	 display: flex;
	 flex-direction: column;
	 align-items: center;
	 justify-content: space-between;
}
 #plan-body .option-card.with-cancel {
	height: 452px;
 }
 #plan-body .option-card .pricing-title {
	 text-transform: uppercase;
	 letter-spacing: 3px;
	 font-size: 13px;
}
 #plan-body .option-card .pricing-month {
	 font-size: 24px;
}
 #plan-body .option-card .pricing-year, #plan-body .option-card .pricing-text {
	 font-size: 18px;
}
 #plan-body .option-card .pricing-description {
	 padding: 0.4em 0;
	 line-height: 21px;
	 letter-spacing: 0.5px;
}
 #plan-body .option-card .btn {
	 margin-top: 10px;
	 color: white;
	 text-transform: uppercase;
	 padding: 9px 24px;
	 border: none;
}
 #plan-body .option-card .btn.focus, #plan-body .option-card .btn:focus, #plan-body .option-card .btn:hover {
	 background-color: initial;
	 text-decoration: underline;
	 position: relative;
	 left: 1px;
	 top: 1px;
	 color: white;
}
 #plan-body .option-card hr {
	 width: 120px;
	 border-width: 3px;
}
 #plan-body .option-card.current {
	 border-style: solid;
	 border-width: 2px;
	 padding: 15px 12px;
}
/* Plan level */
#plan-body .Inventory, .management-modal .Inventory, .plan-modal .Inventory {
	border-color: #0d6efd;
}
#plan-body .Inventory .pricing-month, .management-modal .Inventory .pricing-month, .plan-modal .Inventory .pricing-month, #plan-body .Inventory .pricing-text, .management-modal .Inventory .pricing-text, .plan-modal .Inventory .pricing-text, #plan-body .Inventory .pricing-year, .management-modal .Inventory .pricing-year, .plan-modal .Inventory .pricing-year, #plan-body .Inventory .fa, .management-modal .Inventory .fa, .plan-modal .Inventory .fa, #plan-body .Inventory .pricing-paragraph, .management-modal .Inventory .pricing-paragraph, .plan-modal .Inventory .pricing-paragraph {
	color: #0d6efd !important;
}
#plan-body .Inventory .btn, .management-modal .Inventory .btn, .plan-modal .Inventory .btn, #plan-body .Inventory .btn:focus, .management-modal .Inventory .btn:focus, .plan-modal .Inventory .btn:focus, #plan-body .Inventory .btn:hover, .management-modal .Inventory .btn:hover, .plan-modal .Inventory .btn:hover, #plan-body .Inventory .btn.focus, .management-modal .Inventory .btn.focus, .plan-modal .Inventory .btn.focus, #plan-body .Inventory hr, .management-modal .Inventory hr, .plan-modal .Inventory hr {
	background-color: #0d6efd;
}
 #plan-body .Inventory, .management-modal .Inventory, .plan-modal .Inventory {
	 border-color: #0d6efd;
}
#plan-body .Traffic, .management-modal .Traffic, .plan-modal .Traffic {
	border-color: #0d6efd;
}
#plan-body .Traffic .pricing-month, .management-modal .Traffic .pricing-month, .plan-modal .Traffic .pricing-month, #plan-body .Traffic .pricing-text, .management-modal .Traffic .pricing-text, .plan-modal .Traffic .pricing-text, #plan-body .Traffic .pricing-year, .management-modal .Traffic .pricing-year, .plan-modal .Traffic .pricing-year, #plan-body .Traffic .fa, .management-modal .Traffic .fa, .plan-modal .Traffic .fa, #plan-body .Traffic .pricing-paragraph, .management-modal .Traffic .pricing-paragraph, .plan-modal .Traffic .pricing-paragraph {
	color: #0d6efd !important;
}
#plan-body .Traffic .btn, .management-modal .Traffic .btn, .plan-modal .Traffic .btn, #plan-body .Traffic .btn:focus, .management-modal .Traffic .btn:focus, .plan-modal .Traffic .btn:focus, #plan-body .Traffic .btn:hover, .management-modal .Traffic .btn:hover, .plan-modal .Traffic .btn:hover, #plan-body .Traffic .btn.focus, .management-modal .Traffic .btn.focus, .plan-modal .Traffic .btn.focus, #plan-body .Traffic hr, .management-modal .Traffic hr, .plan-modal .Traffic hr {
	background-color: #0d6efd;
}
 #plan-body .Traffic, .management-modal .Traffic, .plan-modal .Traffic {
	 border-color: #0d6efd;
}
 #plan-body .Enterprise .pricing-month, .management-modal .Enterprise .pricing-month, .plan-modal .Enterprise .pricing-month, #plan-body .Enterprise .pricing-text, .management-modal .Enterprise .pricing-text, .plan-modal .Enterprise .pricing-text, #plan-body .Enterprise .pricing-year, .management-modal .Enterprise .pricing-year, .plan-modal .Enterprise .pricing-year, #plan-body .Enterprise .fa, .management-modal .Enterprise .fa, .plan-modal .Enterprise .fa, #plan-body .Enterprise .pricing-paragraph, .management-modal .Enterprise .pricing-paragraph, .plan-modal .Enterprise .pricing-paragraph {
	 color: #c4a03f !important;
}
 #plan-body .Enterprise .btn, .management-modal .Enterprise .btn, .plan-modal .Enterprise .btn, #plan-body .Enterprise .btn:focus, .management-modal .Enterprise .btn:focus, .plan-modal .Enterprise .btn:focus, #plan-body .Enterprise .btn:hover, .management-modal .Enterprise .btn:hover, .plan-modal .Enterprise .btn:hover, #plan-body .Enterprise .btn.focus, .management-modal .Enterprise .btn.focus, .plan-modal .Enterprise .btn.focus, #plan-body .Enterprise hr, .management-modal .Enterprise hr, .plan-modal .Enterprise hr {
	 background-color: #c4a03f;
}
 #plan-body .btn.btn-secondary, #plan-body .btn.btn-secondary:hover, #plan-body .btn.btn-secondary:focus {
	background-color: #6c757d !important;
 }
 #plan-body .Business, .management-modal .Business, .plan-modal .Business {
	 border-color: #ff9b53;
}
 #plan-body .Business .pricing-month, .management-modal .Business .pricing-month, .plan-modal .Business .pricing-month, #plan-body .Business .pricing-text, .management-modal .Business .pricing-text, .plan-modal .Business .pricing-text, #plan-body .Business .pricing-year, .management-modal .Business .pricing-year, .plan-modal .Business .pricing-year, #plan-body .Business .fa, .management-modal .Business .fa, .plan-modal .Business .fa, #plan-body .Business .pricing-paragraph, .management-modal .Business .pricing-paragraph, .plan-modal .Business .pricing-paragraph {
	 color: #ff9b53;
}
 #plan-body .Business .btn, .management-modal .Business .btn, .plan-modal .Business .btn, #plan-body .Business .btn:focus, .management-modal .Business .btn:focus, .plan-modal .Business .btn:focus, #plan-body .Business .btn:hover, .management-modal .Business .btn:hover, .plan-modal .Business .btn:hover, #plan-body .Business .btn.focus, .management-modal .Business .btn.focus, .plan-modal .Business .btn.focus, #plan-body .Business hr, .management-modal .Business hr, .plan-modal .Business hr {
	 background-color: #ff9b53;
}
 #plan-body .Professional, .management-modal .Professional, .plan-modal .Professional {
	 border-color: #04a778;
}
 #plan-body .Professional .pricing-month, .management-modal .Professional .pricing-month, .plan-modal .Professional .pricing-month, #plan-body .Professional .pricing-text, .management-modal .Professional .pricing-text, .plan-modal .Professional .pricing-text, #plan-body .Professional .pricing-year, .management-modal .Professional .pricing-year, .plan-modal .Professional .pricing-year, #plan-body .Professional .fa, .management-modal .Professional .fa, .plan-modal .Professional .fa, #plan-body .Professional .pricing-paragraph, .management-modal .Professional .pricing-paragraph, .plan-modal .Professional .pricing-paragraph {
	 color: #04a778;
}
 #plan-body .Professional .btn, .management-modal .Professional .btn, .plan-modal .Professional .btn, #plan-body .Professional .btn:focus, .management-modal .Professional .btn:focus, .plan-modal .Professional .btn:focus, #plan-body .Professional .btn:hover, .management-modal .Professional .btn:hover, .plan-modal .Professional .btn:hover, #plan-body .Professional .btn.focus, .management-modal .Professional .btn.focus, .plan-modal .Professional .btn.focus, #plan-body .Professional hr, .management-modal .Professional hr, .plan-modal .Professional hr {
	 background-color: #04a778;
}
 #plan-body .Startup, .management-modal .Startup, .plan-modal .Startup {
	 border-color: #2c7dfa;
}
 #plan-body .Startup .pricing-month, .management-modal .Startup .pricing-month, .plan-modal .Startup .pricing-month, #plan-body .Startup .pricing-text, .management-modal .Startup .pricing-text, .plan-modal .Startup .pricing-text, #plan-body .Startup .pricing-year, .management-modal .Startup .pricing-year, .plan-modal .Startup .pricing-year, #plan-body .Startup .fa, .management-modal .Startup .fa, .plan-modal .Startup .fa, #plan-body .Startup .pricing-paragraph, .management-modal .Startup .pricing-paragraph, .plan-modal .Startup .pricing-paragraph {
	 color: #2c7dfa;
}
 #plan-body .Startup .btn, .management-modal .Startup .btn, .plan-modal .Startup .btn, #plan-body .Startup .btn:focus, .management-modal .Startup .btn:focus, .plan-modal .Startup .btn:focus, #plan-body .Startup .btn:hover, .management-modal .Startup .btn:hover, .plan-modal .Startup .btn:hover, #plan-body .Startup .btn.focus, .management-modal .Startup .btn.focus, .plan-modal .Startup .btn.focus, #plan-body .Startup hr, .management-modal .Startup hr, .plan-modal .Startup hr {
	 background-color: #2c7dfa;
}
 #plan-body .Free, .management-modal .Free, .plan-modal .Free {
	 border-color: #64738b;
}
 #plan-body .Free .pricing-month, .management-modal .Free .pricing-month, .plan-modal .Free .pricing-month, #plan-body .Free .pricing-text, .management-modal .Free .pricing-text, .plan-modal .Free .pricing-text, #plan-body .Free .pricing-year, .management-modal .Free .pricing-year, .plan-modal .Free .pricing-year, #plan-body .Free .fa, .management-modal .Free .fa, .plan-modal .Free .fa, #plan-body .Free .pricing-paragraph, .management-modal .Free .pricing-paragraph, .plan-modal .Free .pricing-paragraph {
	 color: #64738b;
}
 #plan-body .Free .btn, .management-modal .Free .btn, .plan-modal .Free .btn, #plan-body .Free .btn:focus, .management-modal .Free .btn:focus, .plan-modal .Free .btn:focus, #plan-body .Free .btn:hover, .management-modal .Free .btn:hover, .plan-modal .Free .btn:hover, #plan-body .Free .btn.focus, .management-modal .Free .btn.focus, .plan-modal .Free .btn.focus, #plan-body .Free hr, .management-modal .Free hr, .plan-modal .Free hr {
	 background-color: #64738b;
}
/* Plan modal */
 .plan-modal-backdrop.modal-backdrop {
	 background-repeat: no-repeat;
	 background-size: 100% auto;
	 opacity: 1 !important;
	 background-color: white;
	 filter: blur(5px);
}
 .plan-modal {
	 top: 53px;
	 z-index: 5;
}
 .plan-modal-backdrop {
	 z-index: 4;
}
 .backdrop-dashboard {
	 background-image: var(--background-image-dashboard-native);
}
 .backdrop-competitors {
	 background-image: var(--background-image-competitors);
}
 .backdrop-hotproducts {
	 background-image: var(--background-image-hotproducts);
}
 .backdrop-emailcampaigns {
	 background-image: var(--background-image-emailcampaigns);
}
.backdrop-inventory {
	background-image: var(--background-image-inventory-native);
}
.backdrop-traffic {
	background-image: var(--background-image-traffic-native);
}

@media only screen and (max-width: 767px) {
	.backdrop-dashboard {
		background-image: var(--background-image-dashboard-native-mobile);
 }
	.backdrop-inventory {
		background-image: var(--background-image-inventory-native-mobile);
	}
	.backdrop-traffic {
		background-image: var(--background-image-traffic-native-mobile);
	}
}

/* Stripe elements */
 .StripeElement {
	 background-color: white;
	 height: 40px;
	 padding: 10px 12px;
	 border-radius: 4px;
	 border: 1px solid transparent;
	 box-shadow: 0 1px 3px 0 #e6ebf1;
	 -webkit-transition: box-shadow 150ms ease;
	 transition: box-shadow 150ms ease;
}
 .StripeElement--focus {
	 box-shadow: 0 1px 3px 0 #cfd7df;
}
 .StripeElement--invalid {
	 border-color: #a94442;
}
 .StripeElement--webkit-autofill {
	 background-color: #fefde5 !important;
}
/* Modal */
 .management-modal .credit-card-modal-body {
	 background-color: #f7f8f9;
}
 .management-modal .credit-card-form .form-group {
	 margin-left: auto;
	 margin-right: auto;
	 max-width: 400px;
}
 .management-modal .error-message {
	 color: #a94442;
	 text-align: center;
}
 .management-modal .modal-dialog {
	max-width: 600px !important;
 }
