.sub-filter-toggle {
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  display: flex;
}
.sub-filter-toggle h5 {
  display: inline-block;
}
.sub-filter-toggle h5:first-child {
  flex: 1;
}
.filter-container, .dropdown-filter-container {
  border-right: 1px solid #ddd;
}
.filter-container hr, .dropdown-filter-container hr {
  display: none;
}
.filter-container {
  padding: 6px 12px 12px 12px;
  border: 1px solid #ddd;
  background-color: white;
  border-top: none;
}
.filter-container .checkbox label > * {
  word-break: break-word;
}
.filter-side-menu-toggle {
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-top: none;
  text-align: center;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.filter-search-box {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.dropdown-filter-container .dropdown {
  width: 100%;
  max-width: 100%;
}
.dropdown-filter-container .dropdown-toggle {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  padding-left: 10px;
  padding-right: 14px;
}
.dropdown-filter-container .dropdown-toggle .caret {
  position: absolute;
  top: 14px;
  right: 6px;
}
.dropdown-filter-container .dropdown-menu {
  min-width: 100%;
}
.filter-entry {
  cursor: pointer;
  padding: 6px 0px 6px 12px;
}
.filter-entry.selected, .filter-entry.deselected {
  font-weight: bold;
}
.filter-entry.deselected {
  text-decoration: line-through;
}
.filter-entry-icon {
  display: inline-block;
  height: 16px;
  /* width: 16px; */
  margin-top: '0.2rem';
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
}
.filter-zone {
  position: sticky;
  z-index: 2;
  top: 50px;
  background-color: #eaeff2;
  margin: 5px 0;
  padding-top: 5px;
}
.filter-zone label:last-of-type {
  margin-right: 1rem;
}
.filter-card .input-group-btn {
  width: auto;
  position: static;
}
.filter-card .dropdown {
  position: static;
}
.filter-card .dropdown-toggle.filter-card-btn-ellipsis {
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 24px;
}
.filter-card .dropdown-toggle.filter-card-btn-ellipsis .caret {
  position: absolute;
  right: 6px;
  top: 14px;
}
.filter-card .dropdown-menu {
  min-width: 300px;
  max-width: 100%;
}
.filter-card .options-area {
  white-space: normal;
  max-height: 350px;
  overflow-y: scroll;
}
.filter-card .options-area .radio-inline, .filter-card .options-area .checkbox-inline {
  margin-left: 0;
  margin-right: 10px;
}
.filter-card .input-group-btn:last-child > .btn, .filter-card .input-group-btn:last-child > .btn-group {
  z-index: auto !important;
}
.keyword-card .dropdown-toggle {
  border-radius: 0;
  border-right: none;
}
.keyword-card .form-control {
  border-radius: 0;
}
.keyword-card .dropdown {
  position: relative;
}
.keyword-card .dropdown-menu {
  min-width: 144px;
}
.filter-logic-operator-menu .caret {
  display: none;
}
.filter-logic-operator-menu .dropdown-toggle {
  padding: 6px;
}
.filter-logic-operator-menu .dropdown-menu {
  min-width: auto;
  width: 72px;
  text-align: center;
  left: calc(50% - 36px);
}
@media only screen and (max-width: 767px) {
  .filter-zone {
    margin: 0 0;
    margin-top: -15px;
 }
  .filter-row, .dropdown-filter-container {
    margin-bottom: 10px;
 }
}
@media only screen and (max-width: 479px) {
  .filter-zone .input-group {
    display: flex;
    flex-wrap: nowrap;
 }
  .filter-zone .input-group-addon {
    width: auto;
    display: flex;
    align-items: center;
 }
  .filter-zone > div > button, .filter-zone > div > label, .filter-zone > div > .primary-text {
    margin-right: 0;
    display: block;
 }
  .filter-card {
    margin-right: 0 !important;
 }
  .filter-card .dropdown, .filter-card .dropdown-toggle, .filter-card .dropdown-menu {
    width: 100%;
 }
  .filter-editor-action-box {
    display: flex;
    width: 100%;
 }
  .filter-editor-action-box button {
    flex: 1;
 }
  .filter-card .dropdown-toggle.filter-card-btn-ellipsis {
    max-width: none;
 }
  .filter-logic-operator-menu {
    width: 100%;
 }
  .filter-logic-operator-menu .dropdown-menu, .filter-logic-operator-menu .dropdown-toggle {
    width: 100%;
    padding: 0px;
 }
  .filter-logic-operator-menu .dropdown-menu {
    left: 0;
 }
}
.minmax-filter--content {
  margin-top: 10px;
  margin-bottom: 10px;
}
.minmax-filter--feedback {
  color: #a94442;
  font-size: 12px;
}
.minmax-filter--btn-group {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.minmax-filter--btn {
  margin-right: 8px;
  flex: 1;
}
.minmax-filter--btn:last-child {
  margin-right: 0px;
}
.category-filter-item__child-category {
  padding-left: 8px;
}
