:root {
  --background-image-dashboard: url('../../../assets/images/backdrop_ifo_anayltics.png');
  --background-image-competitors: url('../../../assets/images/backdrop_ifo_competitors.png');
  --background-image-hotproducts: url('../../../assets/images/backdrop_ifo_hotproducts.png');
  --background-image-emailcampaigns: url('../../../assets/images/backdrop_ifo_email.png');
  --background-image-track: url('../../../assets/images/backdrop_ifo_track.png');
  --background-image-dashboard-native: url('../../../assets/images/backdrop_dashboard_new.png');
  --background-image-dashboard-native-mobile: url('../../../assets/images/backdrop_dashboard_new_mobile.png');
  --background-image-inventory-native: url('../../../assets/images/backdrops/backdrop_inventory.png');
  --background-image-inventory-native-mobile: url('../../../assets/images/backdrops/backdrop_inventory_mobile.png');
  --background-image-traffic-native: url('../../../assets/images/backdrops/backdrop_traffic.png');
  --background-image-traffic-native-mobile: url('../../../assets/images/backdrops/backdrop_traffic_mobile.png');
}