.rank-1 .badge {
  background-color: #5cb85c;
}

.rank-2 .badge {
  background-color: #5bc0de;
}

.rank-3 .badge {
  background-color: #f0ad4e;
}

.me .badge {
  background-color: #0070ba;
  vertical-align: top;
}