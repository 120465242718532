/*Tabs*/
.app-tabs, .config-tabs {
  position: relative;
}
.app-tabs .nav-tabs, .config-tabs .nav-tabs {
  border-bottom: none;
}
.app-tabs .nav-tabs li.active, .config-tabs .nav-tabs li.active {
  z-index: 1;
  top: -1px;
}
.app-tabs .nav > li > a, .config-tabs .nav > li > a {
  padding: 12px 15px;
}
.app-tabs .tab-header-config-button-wrapper, .config-tabs .tab-header-config-button-wrapper {
  display: flex;
}
.config-tabs .table-responsive {
  overflow: visible;
}
.config-tabs .table > tbody > tr > td {
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .config-tabs .table-responsive {
    overflow-x: auto;
 }
}
.tab-content {
  border-radius: 0 4px 4px 4px;
  border: 1px solid #ddd;
  padding: 25px;
  position: relative;
  top: -1px;
  background-color: white;
}
.param-container {
  padding: 0 26px;
}
.param-container td {
  padding-left: 5px;
}
.param-container td:first-child {
  width: 25px;
}
.param-container .checkbox, .param-container .radio {
  margin: 0;
}
.param-container .checkbox label, .param-container .radio label {
  vertical-align: middle;
}
@media only screen and (max-width: 479px) {
  .responsive-tab-headers {
    width: 100%;
    display: flex;
    flex-flow: row;
 }
  .responsive-tab-headers .prev-tab, .responsive-tab-headers .next-tab {
    width: 40px;
 }
  .responsive-tab-headers > li.active {
    flex: 1;
 }
  .responsive-tab-headers > li.active > a {
    width: 100%;
    text-align: center;
 }
}
@media only screen and (max-width: 600px) {
  #subscribers-body .responsive-tab-headers, #dashboard-body .responsive-tab-headers {
    width: 100%;
    display: flex;
    flex-flow: row;
 }
  #subscribers-body .responsive-tab-headers .hidden-xxs, #dashboard-body .responsive-tab-headers .hidden-xxs {
    display: none;
 }
  #subscribers-body .responsive-tab-headers .block-xxs, #dashboard-body .responsive-tab-headers .block-xxs {
    display: block !important;
 }
  #subscribers-body .responsive-tab-headers .prev-tab, #dashboard-body .responsive-tab-headers .prev-tab, #subscribers-body .responsive-tab-headers .next-tab, #dashboard-body .responsive-tab-headers .next-tab {
    width: 40px;
 }
  #subscribers-body .responsive-tab-headers > li.active, #dashboard-body .responsive-tab-headers > li.active {
    flex: 1;
 }
  #subscribers-body .responsive-tab-headers > li.active > a, #dashboard-body .responsive-tab-headers > li.active > a {
    width: 100%;
    text-align: center;
 }
}
@media only screen and (max-width: 767px) {
  .tab-header-config-button-wrapper {
    width: 100%;
 }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  #dashboard-body .visible.filter-row + div li.hidden-sm {
    display: none !important;
 }
  #dashboard-body .visible.filter-row + div li.hidden-md {
    display: block !important;
 }
}
