.faq body, .faq h1, .faq h2, .faq h3, .faq h4, .faq h5, .faq h6 {
  color: #141d67;
  margin: 0;
  padding: 0;
}
.faq h1 {
  font-size: 2.8em;
}
.faq h2 {
  display: none;
}
.faq h4 {
  font-size: 1.57143em;
}
.faq h1, .faq h2, .faq h3, .faq h4, .faq h5, .faq h6 {
  font-weight: 400;
  margin-bottom: 0.5rem;
  line-height: 1.1;
}
.faq p {
  color: #2c4267;
  line-height: 2;
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.faq b, .faq strong {
  font-weight: bolder;
}
.faq .container {
  max-width: 820px;
}
@media (max-width: 768px) {
  .faq .container {
    max-width: 720px;
 }
}
@media (max-width: 554px) {
  .faq .container {
    max-width: 520px;
 }
  .faq h1 {
    display: none;
 }
  .faq h2 {
    display: inline;
 }
}
