.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  z-index: 1;
}

.pagination>.active>a,
.pagination>.active>span {
  background-color: #0070ba;
  border-color: #0070ba;
}

.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background-color: #005ea6;
  border-color: #005ea6;
}

.pagination {
  margin: 0;
  margin-left: 3px;
  vertical-align: middle;
}

.pagination-container {
  display: flex;
  text-align: right;
  align-items: center;
}

.perpage-container {
  display: flex;
  width: 50%;
  align-items: center;
}

.perpage-container .dropdown {
  margin-right: 3px;
}

.perpage-container .dropdown-menu,
.perpage-container .dropdown-toggle {
  min-width: 80px;
}

@media only screen and (max-width: 767px) {
  .perpage-container {
    display: none;
  }
  .pagination-container {
    width: 100%;
    text-align: center;
  }
  .pagination-container>span {
    display: none;
  }
}
