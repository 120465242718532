[draggable="true"] {
  cursor: move;
}
.dragged-entry>* {
  opacity: 0.5;
}
.dragged-over-entry.dragging-up {
  border-top: 3px double #0070ba;
  padding-top: 3px;
}
.dragged-over-entry.dragging-down {
  border-bottom: 3px double #0070ba;
  padding-bottom: 3px;
}