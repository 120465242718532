.list-table th, .list-table td {
  vertical-align: middle !important;
}
.list-table .sortable {
  cursor: pointer;
}
.list-table .sortable > span {
  display: flex;
  flex-flow: row;
}
.list-table .sortable .header-text {
  flex: 1;
}
.list-table .sortable .fa-caret-up, .list-table .sortable .fa-caret-down {
  margin-left: 3px;
  width: 8px;
  position: relative;
  top: 4px;
}
.list-table img {
  max-height: 60px;
  max-width: 100px;
}
.list-table .sub-content {
  color: #aaa;
  font-size: 80%;
}
.list-table .item-rank-tooltip {
  max-width: 256px;
}
.simple-responsive-table .row {
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}
.simple-responsive-table .row > div {
  padding-top: 5px;
  padding-bottom: 5px;
  word-wrap: break-word;
}
.simple-responsive-table .responsive-table-button-cell .btn {
  margin-top: 3px;
}
.simple-responsive-table .responsive-table-button-cell .btn:first-child {
  margin-top: 0px;
}
.simple-responsive-table.no-header .row:first-child {
  border-top: 1px solid #aaa;
}
.simple-responsive-table.no-header .row:last-child {
  border-bottom: 1px solid #aaa;
}
.simple-responsive-table.no-header .row > div {
  border-bottom: none;
}
.simple-responsive-table.no-header .row > div:last-child {
  border-top: 1px solid #aaa;
}
.simple-responsive-table .refresh-token-section {
  display: flex;
}
.simple-responsive-table .refresh-token-section .refresh-token-section-text {
  flex: 1;
}
.simple-responsive-table .refresh-token-section .fa-refresh {
  cursor: pointer;
  color: #005ea6;
}
.light-gray-header {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
}
tr.selected {
  color: #0070ba;
  background-color: #eee;
}
@media (max-width: 767px) {
  .simple-responsive-table .row:last-child {
    margin-bottom: 0;
 }
  .simple-responsive-table.no-header .row {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
 }
  .simple-responsive-table .responsive-table-button-cell .btn {
    margin-top: 0px;
    margin-left: 3px;
 }
  .simple-responsive-table .responsive-table-button-cell .btn:first-child {
    margin-left: 0px;
 }
}
.auto-insurance-rules-table {
  border-spacing: 25px 0;
  color: #808080;
}
.auto-insurance-rules-table tr {
  border-bottom: 1px solid #ced4da;
}
.auto-insurance-rules-table > tbody > tr:last-child {
  border-bottom: none;
}
.auto-insurance-rules-table td {
  padding: 15px;
  background-color: #f5f5f5;
}
.auto-insurance-rules-table th {
  font-weight: inherit;
  padding: 15px 15px 0 15px;
}

.shipping-table-layout {
  table-layout: auto;
}