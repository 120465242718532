/* .btn {
	 color: #0070ba;
}
 */
/* .btn.show {
	 color: #fff !important;
	 background-color: #0070ba;
}
 */
/* .btn.close:hover {
	 background-color: #0d6efd;
}
 */
 .btn:hover, .btn:focus {
  border-color: #fff !important;
}
/* .btn-outline-secondary {
  color: #0070ba !important;
}
*/
/* .btn-outline-secondary:hover {
  color: #fff !important;
}
*/
.btn-primary {
  background-color: #0d6efd;
  border-color: transparent;
}
.btn-primary:hover {
  background-color: #0d6efd;
}
.export-btn-box {
  text-align: right;
  padding-right: 5px;
}
.export-btn-box .fa-cloud-download {
  margin-right: 4px;
}
/* Switch button */
.switch-handle.btn {
  background-color: #eee;
}
/* Dropdown */
.app-tabs .dropdown button {
  width: 100%;
  text-align: left;
  padding: 6px 10px;
}
.app-tabs .caret {
  float: right;
  position: relative;
  top: 8px;
  margin-left: 5px;
}
.app-tabs .dropdown-menu {
  width: 102%;
  min-width: auto;
  left: -1px;
}
.app-tabs #dashboard-product-report-menu + .dropdown-menu, .app-tabs #dashboard-marketing-report-menu + .dropdown-menu {
  width: auto;
}
.app-tabs .dropdown-menu-right {
  width: auto;
  left: auto;
}
.app-tabs .form-horizontal .dropdown-menu {
  width: auto;
}
.app-tabs .dropdown-long-text .dropdown-menu {
  width: auto;
  left: 0px;
}
.app-tabs .dropdown-long-text .dropdown-menu-right {
  width: auto;
  right: 0px;
  left: auto;
}
/* .dropdown b, .dropdown .fa {
  color: #0070ba;
}
*/
/* .dropdown:hover b, .dropdown .fa {
  color: #fff;
}
*/
.dropdown-menu {
  max-height: 85vh;
  overflow-y: scroll;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  background-color: #0d6efd;
}
.dropdown-menu > .active > a .fa, .dropdown-menu > .active > a:focus .fa, .dropdown-menu > .active > a:hover .fa {
  color: white;
}
.full-width-dropdown {
  width: 100%;
}
.full-width-dropdown .dropdown-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-width-dropdown .dropdown-toggle *:first-child {
  flex: 1;
  text-align: left;
}
.full-width-dropdown .dropdown, .full-width-dropdown .dropdown-menu, .full-width-dropdown .dropdown-toggle {
  width: 100%;
}
.full-width-dropdown.left .dropdown, .full-width-dropdown.left .dropdown-menu, .full-width-dropdown.left .dropdown-toggle {
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    background-color: #0d6efd;
    color: white;
 }
}
/* email buttons */
.save-hint-btn, .email-preview-button {
  float: right;
  position: relative;
  z-index: 1;
}
.save-hint-btn .save-hint-icon, .email-preview-button .save-hint-icon {
  margin-right: 5px;
  font-size: 120%;
}
.email-preview-button {
  z-index: 2;
}
.email-preview-button .dropdown, .email-preview-button .dropdown-toggle {
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .save-hint-btn, .email-preview-button {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    float: none;
 }
  .save-hint-btn .dropdown-menu, .email-preview-button .dropdown-menu {
    width: 100%;
 }
  .save-hint-xxs {
    margin-top: 5px;
 }
  .save-hint-xxs .fa {
    margin-right: 5px;
 }
  .app-tabs .dropdown-long-text {
    width: 100%;
 }
  .app-tabs .dropdown-long-text .dropdown, .app-tabs .dropdown-long-text .dropdown-menu, .app-tabs .dropdown-long-text .dropdown-toggle > b {
    width: 100%;
    font-size: 12px;
 }
}
