.retention-heatmap-data-label {
  margin: 0 0 0 0;
  font-family: Lato, sans-serif;
}

.retention-heatmap {
  overflow-x: auto;
}

.retention-heatmap .highcharts-grid-line {
	stroke-width: 0px;
	stroke: #FFFFFF;
}

.retention-heatmap .highcharts-tooltip span {
  z-index: 9999 !important;
  top:2px !important;
  left:2px !important;
}

.retention-heatmap .highcharts-tooltip span .retention-heatmap-tooltip{
  background-color:white;
  padding:6px; 
  z-index:9999 !important;
  margin-bottom:-14px;
  margin-right:-14px;
}