@import './backdropbackground.css';

.terms-privacy-modal-backdrop.in {
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1;
  background-position-x: center;
  background-position-y: 50px;
  background-color: white;
  filter: blur(5px);
  bottom: -10px;
}
.modal-backdrop-dashboard {
  background-image: var(--background-image-dashboard);
}
.modal-backdrop-competitors {
  background-image: var(--background-image-competitors);
}
.modal-backdrop-hotproducts {
  background-image: var(--background-image-hotproducts);
}
.modal-backdrop-emailcampaigns {
  background-image: var(--background-image-emailcampaigns);
}
.modal-backdrop-track {
  background-image: var(--background-image-track);
}