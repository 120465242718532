@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css';
/* @import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'; */
@import '../../../node_modules/react-daterange-picker/dist/css/react-calendar.css';
@import 'https://fonts.googleapis.com/css?family=Nunito&display=swap';
@import './modules/general.css';
@import './modules/tabs.css';
@import './modules/buttons.css';
@import './modules/pagination.css';
@import './modules/frame.css';
@import './modules/chartgrid.css';
@import './modules/rangeselector.css';
@import './modules/table.css';
@import './modules/misc.css';
@import './modules/retention.css';
@import './modules/filter.css';
@import './modules/thirdparty.css';
@import './modules/plan.css';
@import './modules/dashboardmap.css';
@import './modules/badges.css';
@import './modules/settings.css';
@import './modules/autocomplete.css';
@import './modules/sharedaccounts.css';
@import './modules/agreementmodal.css';
@import './modules/textinput.css';
@import './modules/staff.css';
@import './modules/draggable.css';
@import './modules/categorytree.css';
@import './modules/claims.css';
@import './modules/inventory.css';

html {
  min-height: 100%;
  position: relative;
}

body {
  font-family: 'Nunito', serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: #eaeff2 !important;
}

#sub-analytics-body,
#sub-analytics-body-channels {
  margin: 52px auto 70px auto;
  padding: 20px;
}

/* #sub-analytics-body-reports {
  margin: 52px auto 70px auto;
} */

#sub-analytics-body > div > h2,
#sub-analytics-body > div > h3,
#sub-analytics-body > div > h4 {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 0;
}
#sub-analytics-body-channels {
  max-width: 750px;
}
.app-title-compact {
  margin-top: 0;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 479px) {
  #sub-analytics-body {
    max-width: 460px;
    padding: 14px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  #sub-analytics-body {
    max-width: 748px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #sub-analytics-body {
    max-width: 972px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #sub-analytics-body {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  #sub-analytics-body {
    max-width: 1350px;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1799px) {
  #sub-analytics-body {
    max-width: 1650px;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2099px) {
  #sub-analytics-body {
    max-width: 1950px;
  }
}

@media only screen and (max-width: 767px) {
  #sub-analytics-body-channels {
    max-width: 90%;
  }
}

.Toastify__toast-container {
  z-index: 999999;
}

.modal {
  z-index: 10002 !important;
}
.modal-backdrop {
  z-index: 10001 !important;
}

