.chart-grid {
  margin: 0;
}
.chart-grid .highcharts-container {
  width: 100% !important;
}
.chart-grid .chart-grid-title {
  font-size: 20px;
  color: #777;
}
.chart-grid .chart-grid-mini-chart {
  height: auto;
  border: 1px solid #aaa;
  margin: 5px;
}
.chart-grid .chart-grid-mini-chart.clickable {
  cursor: pointer;
}
.chart-grid .chart-grid-mini-chart.clickable.focused {
  border-width: 2px;
  border-color: #0070ba;
}
.chart-grid .chart-grid-summary-metric-box {
  padding: 5px 10px;
  border: 1px solid #aaa;
  margin: 5px;
  border-radius: 4px;
}
.chart-grid .chart-grid-summary-metric-box h3 {
  margin-top: 10px;
  color: #0070ba;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chart-grid .chart-grid-summary-metric-box .previous-stat, .chart-grid .chart-grid-summary-metric-box .inline-submetric {
  text-align: right;
  font-weight: bold;
}
.chart-grid .chart-grid-summary-metric-box .previous-stat {
  font-size: 12px;
}
.chart-grid .chart-grid-summary-metric-box .inline-submetric {
  font-size: 18px;
  color: gray;
}
.chart-grid .chart-grid-summary-metric-box .previous-stat.negative {
  color: #bd243b;
}
.chart-grid .chart-grid-summary-metric-box .previous-stat.negative .stat-percentage {
  border-bottom: 1px solid #bd243b;
}
.chart-grid .chart-grid-summary-metric-box .previous-stat.positive {
  color: #24bd6e;
}
.chart-grid .chart-grid-summary-metric-box .previous-stat.positive .stat-percentage {
  border-bottom: 1px solid #24bd6e;
}
.chart-grid .chart-grid-summary-metric-box > p {
  font-size: 16px;
  color: #333;
}
.chart-grid .empty-chart-message .title {
  padding-left: 10.5px;
  padding-top: 5px;
  font-size: 18px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
}
.chart-grid .chart-grid-mini-chart .empty-chart-message, .chart-grid .custom-chart-box .empty-chart-message {
  height: 100%;
}
.chart-grid .chart-grid-mini-chart .empty-chart-message .message, .chart-grid .custom-chart-box .empty-chart-message .message {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-33px);
}
.chart-grid .chart-grid-mini-chart.with-legend .empty-chart-message .message {
  margin-top: 140px;
  text-align: center;
}
.chart-grid .dropdown-row {
  margin-bottom: 10px;
}
.chart-grid .dropdown {
  margin: 0px 5px;
}
.chart-grid .metric-selector .dropdown {
 /* width: 185px;
  */
}
.chart-grid .group-by-menu {
 /* text-align: right;
  */
}
.chart-grid .group-by-menu .dropdown {
 /* width: 90px;
  */
}
.chart-grid .dropdown-right {
  text-align: right;
}
.chart-grid .customer-selector {
  display: inline-block;
}
.chart-grid .customer-selector.right {
  float: right;
}
.chart-grid .customer-menu-tag {
  display: none;
}
.chart-grid .custom-chart-box {
  border: 1px solid #aaa;
  margin: 5px;
  height: 305px;
}
.chart-grid .custom-chart-box .custom-chart-type-menu {
  position: absolute;
  display: table;
  top: 5px;
  right: 5px;
  border: 1px solid #aaa;
  z-index: 1;
  border-top: none;
  border-right: none;
  border-bottom-left-radius: 4px;
}
.chart-grid .custom-chart-box .custom-chart-type-menu .fa {
  font-size: 18px;
  color: #0070ba;
  padding: 5px;
  opacity: 0.5;
  display: table-cell;
  cursor: pointer;
}
.chart-grid .custom-chart-box .custom-chart-type-menu .fa.active {
  opacity: 1;
}
.chart-grid .chart-grid-mini-chart, .chart-grid .custom-chart-box {
  border-radius: 4px;
  overflow: hidden;
}
.chart-grid .seller-tracking-section {
  border: 1px solid #aaa;
  padding: 0px 10px;
}
.chart-grid .seller-tracking-section.no-data {
  height: 100px;
}
.chart-grid .seller-tracking-section.no-data .empty-chart-message .message {
  text-align: center;
  margin-top: 15px;
}
.chart-grid .seller-selector {
  max-height: 200px;
  margin-bottom: 10px;
  overflow-y: auto;
}
@media only screen and (min-width: 768px) {
  .chart-grid .col-sm-4 > .seller-selector {
    max-height: 500px;
    height: 500px;
    margin-bottom: 0;
 }
}
@media only screen and (min-width: 992px) {
  .chart-grid .col-md-4 > .seller-selector, .chart-grid .col-lg-3 > .seller-selector, .chart-grid .col-md-3 > .seller-selector {
    max-height: 500px;
    height: 500px;
    margin-bottom: 0;
 }
}
@media only screen and (max-width: 479px) {
  .chart-grid .dropdown-row {
    text-align: center;
    display: flex !important;
 }
  .chart-grid .dropdown-row .metric-menu-tag {
    width: 20px;
    position: relative;
    top: 0.5em;
 }
  .chart-grid .dropdown-row > .dropdown {
    flex: 1;
 }
}
@media only screen and (max-width: 500px) {
  .chart-grid .customer-selector {
    display: flex !important;
    text-align: center;
 }
  .chart-grid .customer-menu-tag {
    display: inline;
    width: 60px;
    position: relative;
    top: 0.5em;
 }
  .chart-grid .customer-selector > .dropdown {
    flex: 1;
 }
  .chart-grid .customer-selector.right {
    float: none;
 }
}
.chart-grid:first-child {
  margin-top: 10px;
}
.chart-grid > div {
  padding: 0;
}
