/*Navbar*/


.navbar {
  --bs-navbar-padding-y: 0;
  --bs-navbar-hover-color: #0070ba;
  padding: 0 !important;
}

.navbar-nav {
  --bs-nav-link-padding-y: 0;
}

.nav-item .active {
  background-color: #e7e7e7;
}

.navbar .navbar-brand:focus,
.navbar .navbar-brand:hover,
.navbar .nav-links>a:focus,
.navbar .nav-links>a:hover,
.navbar .navbar-nav>.open>a,
.navbar .navbar-nav>.open>a:focus,
.navbar .navbar-nav>.open>a:hover {
  color: #0070ba !important;

  .fa {
    color: #0070ba;
  }
}

.navbar .navbar-nav>li>a>.fa {
  color: inherit;
}

.navbar-nav>li>.dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.navbar-brand {
  padding: 0 15px;
  margin-top: -4px;
  margin-bottom: -4px;
}

.navbar-brand img {
  max-height: 50px;
}

.navbar-default .user-name {
  max-width: 180px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: baseline;
  vertical-align: -webkit-baseline-middle;
}

.navbar-default .no-link>a {
  cursor: default;
}

.nav-link-container.inline-block {
  margin: 0;
  padding: 0;
}
.nav-link-container.nav-item {
  font-size: 0.9rem;
  padding: 0 15px;
}

.nav-link-dropdown {
  z-index: 1;
  display: none;
}

.nav-link-dropdown > button {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-width: 0px;
  background-color: white;
  border-color: #dee2e6;
  color: black;
}

.nav-link-dropdown > button:hover {
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
  border-bottom-width: 0px!important;
  background-color: white!important;
  border-color: #dee2e6!important;
  color: black!important;
}

.nav-link-dropdown > button:focus {
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
  border-bottom-width: 0px!important;
  background-color: white!important;
  border-color: #dee2e6!important;
  color: black!important;
}

.nav-link-dropdown > button > span:hover {
  color: black!important;
}


@media (max-width: 896px) {
  .nav-link-mask > li > button {
    display: none;
  }
  .nav-link-dropdown {
    display: block;
  }
  .nav-link-statistics {
    flex-wrap: nowrap !important;
    overflow-y: hidden;
    overflow-x: scroll;
  }
}
@media (min-width: 768px) {
  .nav-link-mask > li > button {
    /* box-shadow: 0px 1px 0px 0px white; */
    position: relative;
    z-index: 1;
  }

  .nav-link-mask > li > button:hover:not(.active) {
    border-color: transparent!important;
  }

  .nav-link-container.nav-item {
    padding: 0 12px;
  }

  .navbar-brand {
    margin-left: 0px !important;
  }
}
@media (min-width: 992px) {
  .nav-link-container.nav-item {
    padding: 0 15px;
  }
}

/*Footer*/
#sub-analytics-footer {
  text-align: center;
  color: #333;
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
  padding-bottom: 15px;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
}

#sub-analytics-footer .row {
  margin: 0;
}

#sub-analytics-footer .copyright {
  cursor: pointer;
}

#sub-analytics-footer .copyright:hover {
  color: #0070ba;
}
