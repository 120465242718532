/* Spacing */
.m0{ margin:0 }
.mt0{ margin-top:0 }
.mr0{ margin-right:0 }
.mb0{ margin-bottom:0 }
.ml0{ margin-left:0 }
.mx0{ margin-left:0; margin-right:0 }
.my0{ margin-top:0; margin-bottom:0 }

.m01{ margin: .3rem }
.mt01{ margin-top: .3rem }
.mr01{ margin-right: .3rem }
.mb01{ margin-bottom: .3rem }
.ml01{ margin-left: .3rem }
.mx01{ margin-left: .3rem; margin-right: .3rem }
.my01{ margin-top: .3rem; margin-bottom: .3rem }

.m1{ margin: .5rem }
.mt1{ margin-top: .5rem }
.mr1{ margin-right: .5rem }
.mb1{ margin-bottom: .5rem }
.ml1{ margin-left: .5rem }
.mx1{ margin-left: .5rem; margin-right: .5rem }
.my1{ margin-top: .5rem; margin-bottom: .5rem }

.m2{ margin: 1rem }
.mt2{ margin-top: 1rem }
.mr2{ margin-right: 1rem }
.mb2{ margin-bottom: 1rem }
.ml2{ margin-left: 1rem }
.mx2{ margin-left: 1rem; margin-right: 1rem }
.my2{ margin-top: 1rem; margin-bottom: 1rem }

.m3{ margin: 2rem }
.mt3{ margin-top: 2rem }
.mr3{ margin-right: 2rem }
.mb3{ margin-bottom: 2rem }
.ml3{ margin-left: 2rem }
.mx3{ margin-left: 2rem; margin-right: 2rem }
.my3{ margin-top: 2rem; margin-bottom: 2rem }

.m4{ margin: 4rem }
.mt4{ margin-top: 4rem }
.mr4{ margin-right: 4rem }
.mb4{ margin-bottom: 4rem }
.ml4{ margin-left: 4rem }
.mx4{ margin-left: 4rem; margin-right: 4rem }
.my4{ margin-top: 4rem; margin-bottom: 4rem }

.mxn1{ margin-left: -.5rem; margin-right: -.5rem; }
.mxn2{ margin-left: -1rem; margin-right: -1rem; }
.mxn3{ margin-left: -2rem; margin-right: -2rem; }
.mxn4{ margin-left: -4rem; margin-right: -4rem; }

.ml-auto{ margin-left:auto }
.mr-auto{ margin-right:auto }
.mx-auto{ margin-left:auto; margin-right:auto; }

.p0{ padding:0 }
.pt0{ padding-top:0 }
.pr0{ padding-right:0 }
.pb0{ padding-bottom:0 }
.pl0{ padding-left:0 }
.px0{ padding-left:0; padding-right:0 }
.py0{ padding-top:0;  padding-bottom:0 }

.p1{ padding: .5rem }
.pt1{ padding-top: .5rem }
.pr1{ padding-right: .5rem }
.pb1{ padding-bottom: .5rem }
.pl1{ padding-left: .5rem }
.py1{ padding-top: .5rem; padding-bottom: .5rem }
.px1{ padding-left: .5rem; padding-right: .5rem }

.p2{ padding: 1rem }
.pt2{ padding-top: 1rem }
.pr2{ padding-right: 1rem }
.pb2{ padding-bottom: 1rem }
.pl2{ padding-left: 1rem }
.py2{ padding-top: 1rem; padding-bottom: 1rem }
.px2{ padding-left: 1rem; padding-right: 1rem }

.p3{ padding: 2rem }
.pt3{ padding-top: 2rem }
.pr3{ padding-right: 2rem }
.pb3{ padding-bottom: 2rem }
.pl3{ padding-left: 2rem }
.py3{ padding-top: 2rem; padding-bottom: 2rem }
.px3{ padding-left: 2rem; padding-right: 2rem }

.p4{ padding: 4rem }
.pt4{ padding-top: 4rem }
.pr4{ padding-right: 4rem }
.pb4{ padding-bottom: 4rem }
.pl4{ padding-left: 4rem }
.py4{ padding-top: 4rem; padding-bottom: 4rem }
.px4{ padding-left: 4rem; padding-right: 4rem }

/* Dimension */
.full-width { width: 100% }
.full-width-flex { flex-basis: 100% }
.full-height { height: 100% }

/* Alignment */
.left { text-align: left }
.right { text-align: right }
.center { text-align: center }
.v-center { align-items: center }
.v-align { vertical-align: middle }
.v-baseline { align-items: baseline }
.justify-center { justify-content: center } 
.align-center { align-items: center } 

/* Color */
.primary-color { color: #337ab7 }
.primary-bg-color { background-color: #337ab7 }
.error-color { color: #a94442 }
.error-bg-color { color: #c9302c }
.default-shadow { box-shadow: 0 0 8px 0 rgba(0,0,0,.498) }
.background-white { background-color: white }

/* Flex box */
.flex-box {
  display: flex!important
}
.flex-1 {
  flex: 1
}
.flex-wrap-true {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
@media only screen and (max-width: 479px) {
  .xxs-flex-wrap {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  .xs-full-width {
    width: 100%;
  }
  .xs-visible {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .xs-invisible {
    display: none;
  }
  .xs-visible {
    display: initial;
  }
}

/* Display */
.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

/* float */
.float-right {
  float: right;
}

.float-left {
  float: left;
}

/* cursor */
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

/* font */
.credit-card-font {
  font-family: Source Code Pro, monospace;
}
.font-size-smaller {
  font-size: 90%;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* text decoration */
.underline {
  text-decoration: underline;
}

/* text weight */
.weight-bold {
  font-weight: bold;
}

/* text transform */
.text-capitalize {
  text-transform: capitalize;
}

/* border */
.default-border {
  /* border: 1px solid #ddd; */
  border-radius: 4px;
}

/* fade */
.fade-in-start {
  opacity: 0;
}

.fade-in-end {
  opacity: 1;
  transition: opacity 0.5s;
}

/* transition */
.transition-width {
  transition: width 0.3s;
}

/* label-input visibility */
.hide-after+label~*,
.hide-after-inline-block+label~* {
  display: none;
}
.hide-after:checked+label,
.hide-after-inline-block:checked+label {
  display: none;
}
.hide-after:checked+label~*,
.hide-after-inline-block:checked+label~* {
  display: block;
}
.hide-after-inline-block:checked+label~* {
  display: inline-block;
}

.hide:checked {
  display: none !important;
}

.hide {
  display: none !important;
}

/* Wrap */
.wrap-pre {
  white-space: pre;
}

/* Misc */
.link-style {
  color: #337ab7;
  text-decoration: underline;
  cursor: pointer;
}

/* Responsive */
@media only screen and (max-width: 399px) {
  .mr0-xxxs {
    margin-right: 0px !important;
  }
}