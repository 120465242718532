/* .field {
  width: 100%;
  font-size: 0.875rem;
  background-color: #f3f4f6;
  border-width: 2px;
  border-color: transparent;
  border-radius: 0.5rem;
  padding: 0.4rem 0.6rem;
}

!tw-bg-white !tw-border !tw-border-slate-300 !focus:tw-border-blue-700 !focus:ring-1 !focus:ring-blue-700 !tw-shadow-sm
.field:focus,
.field:focus-visible {
  border-color: #1d4ed8;
  box-shadow: none;
} */

.field {
  height: 36px;
  width: 100%;
  font-size: 0.875rem;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  border-radius: 0.5rem;
  padding: 0.4rem 0.6rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.field:focus,
.field:focus-visible {
  border-color: #1d4ed8;
  box-shadow: 0 0 0 1px #1d4ed8;
}

.myselect {
  height: 36px;
  width: 100%;
  font-size: 0.875rem;
  border-width: 1px;
  border-radius: 0.5rem;
  padding: 0.4rem 1.25rem 0.4rem 0.6rem;
  appearance: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
}

.myselect:focus,
.myselect:focus-visible {
  border-color: #1d4ed8;
  box-shadow: none;
  box-shadow: 0 0 0 1px #1d4ed8;
}
